class ZIDXAccountMlsGridServerEdit implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountMlsGridServerEditContainer";
    }
    getMatchURL(){
        return "/account/data-feeds/mls-grid-server-logins/edit";
    }
    render(){
        // console.log("mls grid server login edit");
    }
}